.darkgreen-value {
    background-color: #63BE7B;
}

.lightgreen-value {

    background-color: #A9D17E;
}

.yellow-value {

    background-color: #F0E684
}

.orange-value {
    background-color: #FFA572;
}

.vlow-value {

    background-color: #F8696B
}